import '../components/Events/LiveChat/LiveChat.css';
import '../styles/globals.css';
import '../styles/tailwind.css';
import '../styles/manifold.css';
import '@splidejs/react-splide/css/core';

import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Provider } from 'react-redux';

import { csrClient } from '../apollo/api/avantArteClient';
import { AAGrowthbookProvider } from '../components/AAGrowthbookProvider';
import { DialogContainer } from '../components/Containers/Dialog';
import { DialogProvider } from '../components/Containers/DialogProvider';
import FooterLocaleVisibilityProvider from '../components/Containers/FooterLocaleVisibilityProvider';
import NavigationStateProvider from '../components/Containers/NavigationStateProvider';
import TimeProvider from '../components/Containers/TimeProvider';
import UserPreferencesProvider from '../components/Containers/UserPreferencesProvider';
import { GoogleOAuth } from '../components/GoogleOAuth';
import { NavigationContextProvider } from '../components/Layout/Navigation/NavigationContext';
import Web3CacheContextProvider from '../components/Web3/Web3Provider/Web3CacheProvider';
import { DEFAULT_USER_PREFERENCES } from '../config/UserPreferences';
import { ApiUserLocationGateway } from '../gateways/ApiUserLocationGateway';
import { CookieUserPreferencesGateway } from '../gateways/CookieUserPreferencesGateway';
import { AuthProvider, FlowProvider, ViewportProvider } from '../providers';
import { AccountProvider } from '../providers/AccountProvider';
import { GoogleTagManager } from '../scripts/GoogleTagManager';
import { HubSpotTag } from '../scripts/HubSpot/HubSpotTag';
import { setupApplicationStore } from '../store';
import { createAnalyticsClient } from '../utils/analytics/client';
import { google, mixpanel, sentry } from '../utils/analytics/providers';
import { AnalyticsRootProvider } from '../utils/analytics/root';

const analyticsClient = createAnalyticsClient([google, mixpanel, sentry]);

const DialogFlowContainer = dynamic(
  () => import('../components/Containers/Dialog/DialogFlowContainer'),
  { ssr: false },
);

const store = setupApplicationStore();

const userPreferencesGateway = new CookieUserPreferencesGateway();
const userLocationGateway = new ApiUserLocationGateway();

export const AppDefault = ({ Component, pageProps }: AppProps) => {
  return (
    <Provider store={store}>
      <NavigationContextProvider>
        <AnalyticsRootProvider context={{ client: analyticsClient }}>
          <ApolloProvider client={csrClient}>
            <FooterLocaleVisibilityProvider>
              <HubSpotTag />
              <UserPreferencesProvider
                defaultUserPreferences={DEFAULT_USER_PREFERENCES}
                userPreferencesGateway={userPreferencesGateway}
                userLocationGateway={userLocationGateway}
              >
                <FlowProvider>
                  <DialogProvider>
                    <TimeProvider>
                      <AuthProvider>
                        <GoogleTagManager />
                        <AAGrowthbookProvider>
                          <Web3CacheContextProvider>
                            <ViewportProvider>
                              <NavigationStateProvider>
                                <GoogleOAuth />
                                <AccountProvider>
                                  <Component {...pageProps} />
                                  <DialogContainer />
                                  <DialogFlowContainer />
                                </AccountProvider>
                              </NavigationStateProvider>
                            </ViewportProvider>
                          </Web3CacheContextProvider>
                        </AAGrowthbookProvider>
                      </AuthProvider>
                    </TimeProvider>
                  </DialogProvider>
                </FlowProvider>
              </UserPreferencesProvider>
            </FooterLocaleVisibilityProvider>
          </ApolloProvider>
        </AnalyticsRootProvider>
      </NavigationContextProvider>
    </Provider>
  );
};
