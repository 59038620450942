import { createContext } from 'react';

interface FooterLocaleVisibility {
  shown: boolean;
  recentLaunchLocation?: string | undefined;
  show: (launchedFrom: string) => void;
  hide: () => void;
}

export const FooterLocaleVisibilityContext =
  createContext<FooterLocaleVisibility>({
    shown: false,
    recentLaunchLocation: void 0,
    show: (_launchedFrom: string) => {},
    hide: () => {},
  });
