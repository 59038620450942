import { setLocalStorage } from '../utils/localStorage';
import { getLocalStorage } from '../utils/localStorage';
import { v4 } from 'uuid';

const anonIdentifierKey = 'anon_identifier_2023_04_13';

// This function is used to persist the identity of a user between authentication
// sessions. This allows us to ensure that a user who signs in and then out will, for
// example be always be shown the same experiments when in the logged out state.
export default function getAnonymousIdentifier() {
  const identifier = getLocalStorage(anonIdentifierKey);

  if (identifier) return identifier;

  const newIdentifier = v4();

  setLocalStorage(anonIdentifierKey, newIdentifier);

  return newIdentifier;
}
