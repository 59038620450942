import axios from 'axios';
import { getCookie, setCookie } from '../utils/cookies';
import {
  ContinentCode,
  CountryCode,
  GetUserLocationResponse,
  UserLocation,
  UserLocationGateway,
} from '../types/Locations';
import { captureError } from '../utils/capture';

export class ApiUserLocationGateway implements UserLocationGateway {
  async getUserLocation(): Promise<GetUserLocationResponse> {
    try {
      const saved = this.savedLocation();
      if (saved) return { location: saved };

      const url = new URL('/api/location', window.location.origin);
      const res = await axios.get<GetUserLocationResponse>(url.toString());

      if (res.data.location) {
        setCookie('detectedCountry', res.data.location.country);
        setCookie('detectedContinent', res.data.location.continent);
      }

      return res.data;
    } catch (err) {
      captureError(err);
      return {};
    }
  }

  private savedLocation(): UserLocation | null {
    const country = getCookie<CountryCode>('detectedCountry');
    const continent = getCookie<ContinentCode>('detectedContinent');

    if (!country || !continent) return null;

    return {
      country,
      continent,
    };
  }
}
