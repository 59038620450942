import React, { useEffect, useState } from 'react';
import UserPreferencesContext from '../../../context/UserPreferencesContext';
import { CountryCode, UserLocationGateway } from '../../../types/Locations';
import {
  CookieConsentState,
  PersistedUserPreferences,
  UnitOfMeasurementCode,
  UserPreferences,
  UserPreferencesContextType,
  UserPreferencesGateway,
} from '../../../types/UserPreferences';
import { inferPreferencesForUserLocation } from '../../../utils/locations';

type Props = {
  userLocationGateway?: UserLocationGateway;
  userPreferencesGateway: UserPreferencesGateway;
  defaultUserPreferences: UserPreferences;
  children?: React.ReactNode;
};

const UserPreferencesProvider: React.FC<Props> = (props) => {
  const [inferred, setInferredPreferences] = useState<PersistedUserPreferences>(
    {},
  );
  const [saved, setSavedPreferences] = useState<PersistedUserPreferences>(
    props.userPreferencesGateway.preferences,
  );

  const setCountry = (countryCode: CountryCode) => {
    props.userPreferencesGateway.setCountry(countryCode);
    setSavedPreferences({ ...props.userPreferencesGateway.preferences });
  };

  const setUnitOfMeasurement = (unit: UnitOfMeasurementCode) => {
    props.userPreferencesGateway.setUnitOfMeasurement(unit);
    setSavedPreferences({ ...props.userPreferencesGateway.preferences });
  };

  const setCookieConsent = (state: CookieConsentState) => {
    props.userPreferencesGateway.setCookieConsent(state);
    setSavedPreferences({ ...props.userPreferencesGateway.preferences });
  };

  useEffect(() => {
    if (saved.currency !== undefined) return;
    if (props.userLocationGateway === undefined) return;

    props.userLocationGateway.getUserLocation().then((result) => {
      if (result.error || result.location === void 0) return;

      setInferredPreferences(inferPreferencesForUserLocation(result.location));
    });
  }, [props.userLocationGateway]);

  const defaults = props.defaultUserPreferences;
  const preferences: UserPreferences = {
    currency: saved.currency || inferred.currency || defaults.currency,
    countryCode:
      saved.countryCode || inferred.countryCode || defaults.countryCode,
    unitOfMeasurement:
      saved.unitOfMeasurement ||
      inferred.unitOfMeasurement ||
      defaults.unitOfMeasurement,
    cookieConsent: saved.cookieConsent || defaults.cookieConsent,
  };

  const context: UserPreferencesContextType = {
    preferences,
    setCountry,
    setUnitOfMeasurement,
    setCookieConsent,
  };

  return (
    <UserPreferencesContext.Provider value={context}>
      {props.children}
    </UserPreferencesContext.Provider>
  );
};

export default UserPreferencesProvider;
