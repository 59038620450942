import { useEffect } from 'react';

let scrollY = 0;
let height = 0;

// See comment on .scroll-lock in globals.css
export const useDisableBodyScrolling = (disabled: boolean) => {
  useEffect(() => {
    if (disabled) {
      scrollY = window.scrollY;
      height = window.innerHeight;

      document.documentElement.style.setProperty(
        '--window-inner-height',
        `${height}px`,
      );
      document.documentElement.style.setProperty(
        '--window-scroll-height',
        `${scrollY}px`,
      );

      document.documentElement.classList.add('scroll-lock');
      document.body.style.setProperty(
        'top',
        `calc(-1 * var(--window-scroll-height))`,
      );
    } else {
      document.body.style.removeProperty('top');
      document.documentElement.classList.remove('scroll-lock');
      if (window.scrollY === 0) window.scrollTo({ top: scrollY });
    }

    return () => {
      document.body.style.removeProperty('top');
      document.documentElement.style.removeProperty('--window-inner-height');
      document.documentElement.style.removeProperty('--window-scroll-height');
    };
  }, [disabled]);
};
