import { useCallback, useEffect, useRef, useState } from 'react';
import { TimeContext } from '../../../context/TimeContext';

const TimeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [now, setNow] = useState(new Date());
  const [subscribers, setSubscribers] = useState(0);
  const timer = useRef<any>();

  const startListening = useCallback(() => setSubscribers((v) => v + 1), []);
  const stopListening = useCallback(
    () => setSubscribers((v) => Math.max(v - 1, 0)),
    [],
  );

  useEffect(() => {
    if (subscribers === 0) {
      clearTimeout(timer.current);
      return;
    }

    const step = () => {
      setNow(() => new Date());
      timer.current = setTimeout(step, 1000);
    };

    timer.current = setTimeout(step, 1000);

    return () => clearTimeout(timer.current);
  }, [subscribers]);

  const ctx = {
    now,
    startListening,
    stopListening,
  };

  return <TimeContext.Provider value={ctx}>{children}</TimeContext.Provider>;
};

export default TimeProvider;
