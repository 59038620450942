import { useRouter } from 'next/router';
import { createContext, useEffect, useMemo } from 'react';

import { buildSignInFlow } from '../features/auth';
import { AuthFlow } from '../features/auth/types';
import { useOnboarding } from '../hooks/useOnboarding';
import { AuthState } from '../types/Auth';
import { useAuth } from './AuthProvider';
import { useFlow } from './FlowProvider';

interface ContextType {
  onboarding?: ReturnType<typeof useOnboarding>;
}

export const AccountContext = createContext<ContextType>({
  onboarding: undefined,
});

interface Props {
  children: React.ReactNode;
}

export const AccountProvider = ({ children }: Props) => {
  const onboarding = useOnboarding();
  const router = useRouter();
  const { authState } = useAuth();
  const { startFlow } = useFlow();

  useEffect(() => {
    const isLoggedOut = authState === AuthState.LOGGED_OUT;
    const pathName = router.pathname;

    if (pathName.startsWith('/profile') && isLoggedOut) {
      if (pathName.includes('frame-upgrade')) {
        startFlow({
          name: AuthFlow.SignInFrameUpgrade,
          steps: buildSignInFlow({ hideCloseButton: true }),
        });
      } else {
        startFlow({
          name: AuthFlow.SignIn,
          steps: buildSignInFlow(),
        });
      }
    }
  }, [authState, router.pathname, startFlow]);

  const context = useMemo(() => {
    return {
      onboarding,
    };
  }, [onboarding]);

  return (
    <AccountContext.Provider value={context}>
      {children}
    </AccountContext.Provider>
  );
};
