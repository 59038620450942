import { ChainId } from './web3';
import type { providers, BigNumber } from 'ethers';
import { AuthState } from '../Auth';

export enum Web3ProviderType {
  METAMASK = 'metamask',
  WALLET_CONNECT = 'walletconnect',
}

export interface Web3LoginOptions {
  provider: Web3ProviderType;
  signingMessage: string;
}
export interface Web3ContextInterface {
  // is the web3 API initiated and the context is ready
  isReady: boolean;
  // possible error
  error?: Error;
  // is the context loading
  isLoading: boolean;
  // the name of the provider/wallet used to authenticate
  walletProvider: Web3ProviderType;
  // the instance of the provider (ethers.js)
  web3Provider?: providers.Web3Provider;
  // the address of the current wallet, may be a wallet which is not connected
  walletAddress?: string;
  // ens address for the current wallet
  ensAddress?: string;
  // chain id can change separately from the wallet-user
  chainId?: ChainId;
  // the currently active net
  // to stay compatible with the useAuth hook,
  // use this to check if web3 user is authenticated
  authState: AuthState;
  // is metamask installed in the browser
  isMetamaskAvailable: boolean;
  showConnectWalletDrawer: boolean;
  openConnectWalletDrawer: () => void;
  closeConnectWalletDrawer: () => void;
  // starts a login flow for web3 (ex opens metamask)
  login: (options: Web3LoginOptions) => void;
  // logs the current user out
  logout: () => void;
  // handle native balance for user
  nativeBalance: {
    fetch: () => Promise<void | BigNumber>;
    loading: boolean;
    settled: boolean;
    value: BigNumber;
    error?: Error;
  };
  // returns an ethers provider for a specified chain,
  // if the connected wallet is not on the requested chain, an infura provider is used
  // this provider cannot sign transactions, use it only to fetch blockchain data.
  getProviderForChain: (chainId: string | number) => providers.Provider;
}
