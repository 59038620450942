import Cookies from 'js-cookie';
import { isBrowser } from '@utils/isBrowser';

export const getCookie = <T extends string>(name: string): T | undefined => {
  return (Cookies.get(name) as T) || undefined;
};

export const setCookie = <T extends string>(name: string, value: T): void => {
  if (!isBrowser()) return;

  const domains = getCookieDomains(window.location.href);
  const expires = new Date('2038-01-01');

  domains.forEach((domain) => {
    Cookies.set(name, value, {
      domain,
      expires,
    });
  });
};

export const removeCookie = (name: string): void => {
  if (!isBrowser()) return;

  const domains = getCookieDomains(window.location.href);
  domains.forEach((domain) => {
    Cookies.remove(name, { domain });
  });
};

export const getCookieDomains = (url: string): string[] => {
  const { hostname } = new URL(url);
  const parts = hostname.split('.');
  const root = parts.slice(-2).join('.');

  switch (root) {
    case 'localhost':
      return [''];
    case 'avantarte.com':
      return ['avantarte.com', '.avantarte.com'];
    default:
      return [hostname];
  }
};
