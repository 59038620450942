import Script from 'next/script';
import { useEffect, useState } from 'react';

import { delay } from '../../utils/promises';
import { isBrowser } from '@utils/isBrowser';

const trackingId = process.env.NEXT_PUBLIC_HUBSPOT_TRACKING_ID;

export const HubSpotTag = () => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    if (
      isBrowser() &&
      trackingId &&
      src === '' &&
      window.location.host !== 'arte.bio'
    ) {
      delay(2000).then(() => {
        setSrc(`//js.hs-scripts.com/${trackingId}.js`);
      });
    }
  }, []);

  return src ? <Script defer src={src} strategy="lazyOnload" /> : null;
};
