import { useEffect, useState } from 'react';
import DialogContext, { DialogOptions } from '../../../context/DialogContext';

export const DialogProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [dialogContent, setDialogContent] = useState<React.ReactNode>(null);
  const [dialogOptions, setDialogOptions] = useState<DialogOptions>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (dialogContent && !open) {
      setOpen(true);
    }
  }, [dialogContent]);

  return (
    <DialogContext.Provider
      value={{
        showDialog: (content, options) => {
          setDialogContent(content);
          setDialogOptions(options);
        },
        open,
        hideDialog: () => {
          setOpen(false);
          setTimeout(() => {
            setDialogContent(null);
          }, 600);
        },
        dialogContent,
        dialogOptions,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};
