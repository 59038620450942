import { getCookie, setCookie } from '../utils/cookies';
import type {
  CookieConsentState,
  PersistedUserPreferences,
  UnitOfMeasurementCode,
  UserPreferencesGateway,
} from '../types/UserPreferences';
import { CountryCode } from '../types/Locations';
import { COUNTRY_DETAILS } from '../constants/locations';

const defaultCurrencyCode = COUNTRY_DETAILS.default.currency.code;

export class CookieUserPreferencesGateway implements UserPreferencesGateway {
  public preferences: PersistedUserPreferences;

  constructor() {
    this.preferences = {
      currency: getCookie(CURRENCY_COOKIE_NAME),
      countryCode: getCookie(COUNTRY_COOKIE_NAME),
      unitOfMeasurement: getCookie(UNIT_COOKIE_NAME),
      cookieConsent: getCookie(COOKIE_CONSENT_COOKIE_NAME),
    };
  }

  public setCountry(countryCode: CountryCode): void {
    setCookie(COUNTRY_COOKIE_NAME, countryCode);
    this.preferences.countryCode = countryCode;

    const currency =
      COUNTRY_DETAILS[countryCode as keyof typeof COUNTRY_DETAILS]?.currency
        .code || defaultCurrencyCode;
    setCookie(CURRENCY_COOKIE_NAME, currency);
    this.preferences.currency = currency;
  }

  public setUnitOfMeasurement(unitOfMeasurement: UnitOfMeasurementCode): void {
    setCookie(UNIT_COOKIE_NAME, unitOfMeasurement);
    this.preferences.unitOfMeasurement = unitOfMeasurement;
  }

  public setCookieConsent(cookieConsent: CookieConsentState): void {
    setCookie(COOKIE_CONSENT_COOKIE_NAME, cookieConsent);
    this.preferences.cookieConsent = cookieConsent;
  }
}

const CURRENCY_COOKIE_NAME = 'currency';
const COUNTRY_COOKIE_NAME = 'country';
const UNIT_COOKIE_NAME = 'unitOfMeasurement';
const COOKIE_CONSENT_COOKIE_NAME = 'cookieConsent';
